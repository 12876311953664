:root{
  --main-bg: #0F111A;
  --main-bg-inverse: #F0EEE550;
  --secondary-bg-inverse: #F0EEE515;
  --link-hover-bg: #717CB480;
  --link-hover-text: #FFFFFF;
  --command: #84FFFF;
  --main-text: #EEFFFF;
  --link-text: #82AAFF;
  --color2-text: #C3E88D;
}

.App {
  display: flex;
  align-items: center;
  text-align: left;
  text-size-adjust: 100%;
}

body {
  display: flex;
  margin: 0;
  color: var(--main-text);
  background: var(--main-bg); 
  font-family: cursor, monospace;
}

  ::-webkit-scrollbar {
    width: 0px;
  }

p {
  line-height: 1.3em;
  margin: 0;
  text-wrap: wrap !important;
  white-space: nowrap;
  font-size: max(1.11111111vw, 16px);
} 

.color2 {
  color: var(--color2-text);
}
.command {
  color: var(--command);
  text-shadow: 0 0 1px var(--command); 
}

.inherit,
a {
  color: var(--link-text);
}
a {
  text-decoration: inherit;
  display: inline-block;
  width: min-content;
  white-space: nowrap;
}
a:hover {
  background: var(--link-hover-bg);
  color: var(--link-hover-text);
}
a:focus {
  outline: 0;
} 
