.NewLines {
    display: flex;
    flex-direction: column;
}

.NewLines p{
    max-width: 45vw;
    @media screen and (max-width: 1450px) {
        max-width: 100vw;
    }
}