.terminal-wrapper {
    border: var(--color2-text) 3px solid;
    height: 100%;
    min-height: calc(100vh - 36px);
    border-radius: 1rem;
    min-width: calc(100vw - 36px); 
    padding: 15px 15px;
    overflow: hidden;
    position: relative;
    /* background-color: var(--secondary-bg-inverse); */

    @media screen and (max-width: 830px) {
        min-height: calc(80vh);
      }
}

.terminal-wrapper-content {
    border: 1px solid var(--main-bg-inverse);
    border-radius: 0.6rem;
    padding: 15px 20px;
    min-height: calc(100vh - 68px);
    
    @media screen and (max-width: 830px) {
        min-height: calc(80vh - 32px);
      }
  }

  @keyframes pan-overlay {
    from {
      background-position: 0 0;
    }
    
    to {
      background-position: 0px -9px;
    }
  }

  .screen-overlay {    
    background: linear-gradient(
        var(--secondary-bg-inverse),
        var(--secondary-bg-inverse) 3px,
      transparent 3px,
      transparent 9px
    );
    background-size: 100% 9px;
    min-height: 100%;
    width: 100%;
    animation: pan-overlay 0.3s infinite linear;
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 0px;
  }

form{
    left: -1000px;
    position: absolute;
}

.cursor{
    font-size: 12px;
    color: var(--main-text) ;
    /* color: #73ABAD; */
    background-color: var(--main-text);
    /* background-color: #73ABAD; */
    position: relative;
    opacity: 0.4;
    height: 1.5em;
    width: 10px;
    max-width: 10px;
    transform: translateY(4px);
    overflow: hidden;
    text-indent: -5px;
    display: inline-block;
    text-decoration: blink;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.hint{
    padding-right: 1rem;
}

#command-field{
    padding: 0 !important ;
    border: 0 !important ;
    caret: var(--link-hover-bg) block !important;
    /* caret: #519975 block !important; */
    color: var(--link-hover-bg);
    /* color: #519975; */
    background: var(--link-hover-bg);
    /* background: #211D1B; */
    font-family: cursor, monospace;
    font-size: 20px;  
}

#command-field:focus-visible {
    outline: none;
}

#command-line{
    /* height: 50px; */
    margin-top: -2px;
    padding-bottom: 20px;
}

.bannerWrapper{
    text-align: center;
}

.bannerWrapper p{
    max-width: 100% !important;
}

.desc{
    display: inline;
    @media screen and (max-width: 640px) {
        display: block;
      }
}

.key-container{
    display: flex;
    @media screen and (max-width: 640px) {
        display: none;
        }
}

.banner{
    display: none;

    @media screen and (min-width: 830px) {
        display: inline !important;
        white-space: pre-wrap;
        text-wrap: nowrap !important;
      }
}

.mobile-banner{
    display: inline;
    white-space: pre-wrap;
    text-wrap: nowrap !important;

    @media screen and (min-width: 830px) {
        display: none !important;
      }
}